<template>
	<div>
        我是首页
    </div>
</template>

<script>
    export default{
        data(){
            return {
            };
        },
        methods: {           
        },
        mounted() {
            //当前所在状态页
            this.activeNameS = this.$route.query.type || '1'
        },
        updated: function () {
            //当前所在状态页
            this.activeNameS = this.$route.query.type || '1'
        }
    }
</script>

<style lang="scss">
    @import "../styles/content.scss";    
	.el-table .cell, .el-table th div, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell{ padding: 0 }
</style>